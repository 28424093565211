(function() {
  'use strict';
  $(document).ready(function(){

    const $dotsContainer =  $('.review-slider__footer');
    $('.review-slides').slick({
      adaptiveHeight: true,
      arrows: false,
      dots: true,
      dotsClass: 'review-slider__dots',
      appendDots: $dotsContainer,
      customPaging: function(slider, i) {
        return $('<a href="javascript:;"></a>');
      },
    });
  });

}());
